import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import styled from 'styled-components'

import Layout from '~/components/Layout'
import BackgroundWrapper from '~/components/BackgroundWrapper'

const Title = styled.h1`
`
const ContentWrapper = styled(BackgroundWrapper)`
  padding: 20px;
  margin: 0 auto;
  max-width: 700px;
`

export default ({ data, location }) => {
  const siteMetadata = get(data, 'site.siteMetadata')
  const pages = get(data, 'allContentfulPage.edges')
    .map(({ node }) => node)

  const [metadata] = get(data, 'allContentfulMetadata.edges')
  const [privacy] = get(data, 'allContentfulPrivacy.edges')

  const navPages = [...pages]
  navPages.push({ title: 'Contattaci', slug: 'contatti' })

  const title = `Informativa sulla privacy | ${siteMetadata.title}`

  return (
    <Layout
      location={location}
      title={title}
      url={siteMetadata.url}
      pages={navPages}
      metadata={metadata.node}
    >
      <ContentWrapper>
        <Title>{privacy.node.title}</Title>
        {documentToReactComponents(privacy.node.body.json)}
      </ContentWrapper>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PrivacyQuery {
    site {
      siteMetadata {
        title
        url
      }
    }
    allContentfulPage(sort: {fields: position}) {
      edges {
        node {
          title
          slug
          content {
            json
          }
          services {
            title
            slug
            heroImage {
              title
              fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
                ...GatsbyContentfulFluid
              }
            }
            body {
              json
            }
          }
          map {
            url
          }
        }
      }
    }
    allContentfulPrivacy {
      edges {
        node {
          title
          body {
            json
          }
        }
      }
    }
    allContentfulMetadata {
      edges {
        node {
          name
          body {
            json
          }
          text {
            text
          }
          keywords
          email
          phone
          cell
          fax
          address
          iva
          cf
          heroImage: image {
            title
            fluid(
              maxWidth: 1180,
              maxHeight: 480,
              resizingBehavior: FILL
            ) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
